export enum FilterOperator {
	eq = 0, // equalTo
	ne = 1, // notEqualTo
	gt = 2, // greaterThan
	ge = 3, // greaterThanOrEqual
	lt = 4, // lessThan
	le = 5, // lessThanOrEqual
	contains = 6,
	startswith = 7,
	endswith = 8,
	indexOf = 9,
	length = 10,
	substring = 11,
	in = 13,
}