import AuthService from '@/shared/application/auth-service-proxy';
import { Permission } from '../models/dto/permissions';
import { IRestResponseDto } from '../models/shared/rest-response-dto';
import Swal from 'sweetalert2';
import Axios from 'axios';

const AppConsts = {
    baseApiUrl: process.env.VUE_APP_BASE_API_URI,
    baseClientUrl: process.env.VUE_APP_BASE_URI,
    isLoading: false,
    appVersion: '1.0.0',
    auth: {
        grantedPermissions: [] as Permission[],
        isGranted(permissionName: string) {
            let returnBool = false;

            if (this.grantedPermissions) {
                for (const [key, value] of Object.entries(this.grantedPermissions)) {
                    if ((value as Permission).name === permissionName) {
                        returnBool = true;
                        break;
                    }
                }
            }

            return returnBool;
        },
        removeProps(): void {
            this.grantedPermissions = [];
        },
        async fillProps(): Promise<void> {
            const authService = new AuthService();
            authService.get<IRestResponseDto<Permission[]>>(
                '/api/permissions'
            ).then((response) => {
                if (!response.isError) {
                    this.grantedPermissions = response.content as unknown as Permission[];
                }
            }).catch(() => {
                window.location.href = '/login';
            });
        },

    },
    swalWithBootstrapButtons: Swal.mixin({
        customClass: {
            confirmButton: 'float-right ml-5 v-btn v-btn--contained theme--light v-size--default primary',
            cancelButton: 'float-right v-btn v-btn--flat v-btn--text theme--light v-size--default grey--text text--darken-3',
        },
        buttonsStyling: false,
        willOpen: (ele) => {
            ele.classList.remove('swal2-styled');
        }
    }),
    overwriteUrls(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV === 'development') {
                console.info('Developing: will not retrieve external settings file. Using default settings');
                resolve('ok');
            } else { 
                Axios.get('/cfg/settings.json').then((response) => {
                    if (response && response.data && typeof (response.data) === 'object') {
                        AppConsts.baseApiUrl = response.data.VUE_APP_BASE_API_URI;
                        AppConsts.baseClientUrl = response.data.VUE_APP_BASE_URI;
                        resolve('ok');
                    } else {
                        console.warn('Error retrieving external settings file. Using default settings');
                        reject();
                    }
                }).catch(() => {
                    console.warn('Error retrieving external settings file. Using default settings');
                    reject();
                });
            }
        });
    }
};

export default AppConsts;