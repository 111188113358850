import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthStore from '@/stores/auth-store';
import Store from '@/stores/index';

// fixes error https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

// By declaring content as variable, it should invoke lazy loading
const home = (r: (arg0: any) => void) => require.ensure([], () => r(require('@/views/home/home-user.vue')));
const users = (r: (arg0: any) => void) => require.ensure([], () => r(require('@/views/users/user-list.vue')));
const roles = (r: (arg0: any) => void) => require.ensure([], () => r(require('@/views/roles/role-list.vue')));
const gateways = (r: (arg0: any) => void) => require.ensure([], () => r(require('@/views/gateways/gateway-list.vue')));
const devices = (r: (arg0: any) => void) => require.ensure([], () => r(require('@/views/devices/device-list.vue')));
const deviceDetails = (r: (arg0: any) => void) => require.ensure([], () => r(require('@/views/devices/device-details.vue')));
const exportMeasurements = (r: (arg0: any) => void) => require.ensure([], () => r(require('@/views/export/export-measurements.vue')));
const endpoints = (r: (arg0: any) => void) => require.ensure([], () => r(require('@/views/endpoints/endpoint-list.vue')));
const map = (r: (arg0: any) => void) => require.ensure([], () => r(require('@/views/map/map.vue')));

const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/',
			meta: {
				requiresAuth: true,
			},
			component: home
		},
		{
			path: '/home',
			name: 'Home',
			meta: {
				title: 'Home',
				icon: 'mdi-home',
				showInMenu: true,
				requiresAuth: true
			},
			component: home
		},
		// =============================================================================
		// User account routes
		// =============================================================================
		{
			path: '/login',
			name: 'login',
			component: require('@/views/login/login.vue').default
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: require('@/views/manage/forgot-password.vue').default
		},
		{
			path: '/reset-password',
			name: 'reset-password',
			component: require('@/views/manage/reset-password.vue').default
		},
		// =============================================================================
		// Custom routes
		// =============================================================================
		{
			path: '/gateway-list/:id?/:protocolName?',
			name: 'Gateways',
			meta: {
				title: 'Gateways',
				icon: 'mdi-router-network',
				showInMenu: true,
				requiresAuth: true,
				permissions: [
					'Permissions_Administration',
					'Permissions_Gateway_Read'
				],
			},
			component: gateways
		},
		{
			path: '/device-list/:id?/:protocolName?',
			name: 'Devices',
			meta: {
				title: 'Devices',
				icon: 'mdi-access-point-network',
				showInMenu: true,
				requiresAuth: true,
				permissions: [
					'Permissions_Administration',
					'Permissions_Device_Read'
				],
			},
			component: devices
		},
		{
			path: '/device-details/:id?/:protocolName?',
			name: 'DeviceDetails',			
			meta: {
				requiresAuth: true,
				permissions: [
					'Permissions_Administration',
					'Permissions_Device_Read'
				],
			},
			props: (route) => ({
				...route.params
			}),
			component: deviceDetails
		},
		{
			path: '/export-measurements',
			name: 'ExportMeasurements',
			meta: {
				title: 'Export',
				icon: 'mdi-export',
				showInMenu: true,
				requiresAuth: true,
				permissions: [
					'Permissions_Administration',
					'Permissions_Sensor_Read',
					'Permissions_Measurement_Read'
				],
			},
			component: exportMeasurements
		},
		{
			path: '/endpoint-list/:id?',
			name: 'Endpoints',
			meta: {
				title: 'Endpoints',
				icon: 'mdi-api',
				showInMenu: true,
				requiresAuth: true,
				permissions: [
					'Permissions_Endpoint_Read'
				],
			},
			component: endpoints
		},
		{
			path: '/map',
			name: 'Map',
			meta: {
				title: 'Map',
				icon: 'mdi-google-maps',
				showInMenu: true,
				requiresAuth: true,
				permissions: [
					'Permissions_Administration',
				],
			},
			component: map
		},
		// =============================================================================
		// Administrator routes
		// =============================================================================
		{
			path: '/user-list/:id?',
			name: 'Users',
			meta: {
				title: 'Users',
				icon: 'mdi-account-multiple',
				subHeader: 'Administration',
				showInMenu: true,
				requiresAuth: true,
				permissions: [
					'Permissions_Administration',
					'Permissions_User_Read'
				],
			},
			component: users
		},
		{
			path: '/role-list/:id?',
			name: 'Roles',
			meta: {
				title: 'Roles',
				icon: 'mdi-briefcase-account',
				subHeader: 'Administration',
				showInMenu: true,
				requiresAuth: true,
				permissions: [
					'Permissions_Administration',
					'Permissions_Role_Read'
				],
			},
			component: roles
		},
		// =============================================================================
		// Configuration routes
		// =============================================================================

	]
});

router.beforeEach((to: any, from: any, next: any) => {
	Store.commit('setRouteState', true);

	if (to.matched.some((record: any) => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (!AuthStore.isSignedIn()) {
			next({
				path: '/login',
				query: { redirect: to.fullPath }
			});
		}
	}

	if (to.meta && to.meta.title) {
		document.title = 'ITS | ' + to.meta.title;
	} else {
		document.title = 'Industrial Things System';
	}

	next(); // make sure to always call next()!
});

router.afterEach(() => {
	Store.commit('setRouteState', false);
});

export default router;