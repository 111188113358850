export default class State {
	/* General */
	public loadingRoute: boolean = false;

	public filterOpened: boolean = false;

	public selectedDate: Date = new Date();

	public periodRangeType: string = 'day';

	/* Dialogs */
}
