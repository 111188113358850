import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop } from 'vue-property-decorator';
import { NameValue } from '../../../../shared/models/dto/general';
import { ChangePasswordInput } from '../../../../shared/models/dto/account';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class ChangePasswordComponent extends AuthComponentBase {
    @Prop() changePasswordDialog!: boolean;
    @Prop() logOut: any;

    refs = this.$refs as any;
    errors: NameValue[] = [];
    changePasswordInput = {} as ChangePasswordInput;
    dialog = false;

    mounted() {
        this.$root.$on('changePasswordDialogChanged',
            (dialog: boolean) => {
                this.dialog = dialog;
            });
    }

    save() {
        if (this.refs.form.validate()) {
            this.authService.post<ChangePasswordInput>('/api/changePassword', this.changePasswordInput)
                .then((response) => {
                    if (response) {
                        this.dialog = false;
                        this.swalToast(2000, 'success', this.t('ChangesSaved'));
                        this.logOut();
                    } else {
                        this.errors = response as unknown as NameValue[];
                    }
                });
        }
    }
}