import { ActionContext, ActionTree } from 'vuex';

import State from './state';

// ActionTree<[current state], [root state]>
const actions: ActionTree<State, State> = {

};

export default actions;
