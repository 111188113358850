import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component } from 'vue-property-decorator';
import LanguageStore from '@/stores/language-store';
import { DateTime } from 'luxon';

@Component({
    components: {
        ChangePassword: require('@/views/_components/profile/change-password/change-password.vue').default,
        ToolbarProgressBar: require('@/views/_components/toolbar-progress-bar/toolbar-progress-bar.vue').default
    }
})
export default class TopMenuComponent extends AuthComponentBase {
    drawer: boolean = true;
    selectedLanguage = {} as ILanguageDto;

    authenticated: boolean = false;
    notification: string = 'Notificaties';
    notifications: string[] = [
        'Mike, John responded to your email',
        'You have 5 new tasks',
        'You\'re now a friend with Andrew',
        'Another Notification',
        'Another One',
    ];

    beforeMount() {
        this.selectedLanguage = LanguageStore.getLanguage();
    }

    mounted() {
        this.authenticated = this.authStore.isSignedIn();
        this.$root.$on('authenticated', (authenticated: boolean) => {
            this.authenticated = authenticated;
        });
    }

    changePasswordDialogChanged(dialog: boolean) {
        this.$root.$emit('changePasswordDialogChanged', dialog);
    }

    drawerChanged() {
        this.$root.$emit('drawerChanged');
    }

    timesheetDrawerChanged() {
        this.$root.$emit('timesheetDrawerChanged');
    }

    changeLanguage(languageCode: string, languageName: string) {
        this.$i18n.locale = languageCode;
        this.selectedLanguage = { languageName, languageCode } as ILanguageDto;
        this.$vuetify.lang.current = languageCode;

        LanguageStore.setLanguage({
            languageCode,
            languageName
        } as any);
    }

    logOut() {
        this.authStore.removeUser();
        this.AppConsts.auth.removeProps();
        this.$root.$emit('authenticated', false);
        this.$stopSignalR();
        this.$router.push({ path: '/login' });
    }

    downloadDebugInfo() {
        const currentLog = localStorage.getItem('ItsLogger');
        const get_browser = () => {
            let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return { name: 'IE', version: (tem[1] || '') };
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\bOPR|Edge\/(\d+)/)
                if (tem != null) { return { name: 'Opera', version: tem[1] }; }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
            return M[0] + ' ' + M[1];
        };

        const get_os = () => {
            const userAgent = window.navigator.userAgent,
                platform = window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'];
            let os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac OS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            return os;
        }

        if (currentLog) {
            let formattedBody = 'Ingelogde gebruiker:\n' + this.authStore.getUser().emailaddress + '\n\nBrowser, OS:\n' + get_browser() + '. ' + get_os() + '\n\nDe volgende informatie is opgeslagen in de browser van de gebruiker:\n\n';

            try {
                let parsedLog = JSON.parse(currentLog);
                parsedLog.forEach((logItem) => {
                    formattedBody += JSON.stringify(logItem) + '\n\n'
                });
            } catch (e) {
                this.swalToast(2000, 'error', 'De debuginformatie kon niet worden geparsed');
                formattedBody += currentLog;
            }

            let blob = new Blob([formattedBody], { type: 'text/plain;charset=utf-8' });

            let a = document.createElement('a');
            a.download = 'Errorlog_ITS_' + DateTime.local().toISODate(); + '.txt';
            a.href = URL.createObjectURL(blob);
            a.click();
        } else {
            this.swalToast(2000, 'warning', 'Er is geen debuginformatie gevonden');

        }
    }
}