import { Component } from 'vue-property-decorator';
import AuthComponentBase from '@/shared/application/auth-component-base';
import { ForgotPasswordInput, ForgotPasswordOutput } from '../../shared/models/dto/account';
import { NameValue } from '../../shared/models/dto/general';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class ForgotPasswordComponent extends AuthComponentBase {
    refs = this.$refs as any;
    forgotPasswordInput = {} as ForgotPasswordInput;
    errors: NameValue[] = [];
    isEmailSent = false;
    resultMessage: string | undefined;

    onSubmit() {
        if (this.refs.form.validate()) {
            this.errors = [];

            this.authService.post<ForgotPasswordOutput>('/api/forgotPassword', this.forgotPasswordInput)
                .then((response) => {
                    if (response) {
                        this.resultMessage = this.t('EMailSentSuccessfully');
                        this.isEmailSent = true;
                    } else {
                        response.errors.forEach(e => this.errors.push(e));
                    }
                });
        }
    }
}