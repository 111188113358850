// Styles
import './scss/main.scss';
import '@sweetalert2/theme-material-ui/material-ui.scss';
import '@mdi/font/scss/materialdesignicons.scss';

// Plugins
import Vue from 'vue';
import VueMasonry from 'vue-masonry-css';
import { Settings } from 'luxon';
import VueI18n from 'vue-i18n';
import InlineSvg from 'vue-inline-svg';
import VueMask from 'v-mask';
import * as VueGoogleMaps from 'vue2-google-maps';

// Custom
import DateFilter from '@/filters/date';
import ReverseArray from '@/filters/reverse-array';
import AppHub from '@/shared/application/hub';
import LanguageStore from '@/stores/language-store';
import vuetify from './plugins/vuetify';
export * from '@/plugins/exception-handler';
import RowPercentage from '@/shared/directives/row-percentage';
import TranslationInput from '@/views/_components/translation-input/translation-input.vue';

// Vuex store
import store from '@/stores';

// Base
import App from './App.vue';
import router from './router';
import AppConsts from './shared/application/auth';

Vue.config.productionTip = false;

Vue.filter('date', DateFilter);
Vue.filter('reverseArray', ReverseArray);

Vue.use(VueMask);
Vue.use(VueI18n);
Vue.use(VueMasonry);
Vue.use(AppHub);
Vue.use(InlineSvg);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDylwy53ks3Xm0rrfvE8IpvbfEP5Elv9Ak',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
        autobindAllEvents: true,
        region: 'NL',
        language: 'nl',
    },
    installComponents: true,
});

Vue.component('inline-svg', InlineSvg);
Vue.component('translation-input', TranslationInput);

Vue.directive('row-percentage', RowPercentage);

// Config luxon
Settings.defaultLocale = 'nl';

const locales = {
    en: require('@/assets/localizations/en.json'),
    nl: require('@/assets/localizations/nl.json')
};

const i18n = new VueI18n({
    locale: LanguageStore.getLanguage().languageCode,
    fallbackLocale: 'en',
    silentFallbackWarn: true,
    messages: locales
});


AppConsts.overwriteUrls().then(() => {
    Vue.prototype.$config = AppConsts;
    new Vue({
        i18n,
        store,
        router,
        vuetify,
        render: h => h(App)
    }).$mount('#app');
});