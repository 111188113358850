import buildQuery from 'odata-query';
import { FilterOperator } from '../models/shared/filter-operator';
import { ODataFilterObject } from '../models/shared/odata-filter-object';

export default class Query {
    public static queryBuilder(queryObj: ODataFilterObject) { //https://github.com/techniq/odata-query
        const filter = [];

        if (queryObj.filter) {
            queryObj.filter.forEach((fil, index) => {
                if (fil.value && fil.value !== '') {
                    let filterObj: any = null;

                    if (fil.operator < 6 || fil.operator === 13) { // Comparison operators
                        filterObj = {};
                        let filterObjVal = {};

                        filterObjVal[FilterOperator[fil.operator]] = fil.value;
                        filterObj[fil.columnName.replace('.', '/')] = filterObjVal;
                    } else { // Logical operators
                        filterObj = `${FilterOperator[fil.operator]}(${fil.columnName.replace('.', '/')},'${fil.value}')`;
                    }
                    filter.push(filterObj);
                }
            });
        }

        let select = null;
        if (queryObj.select) {
            select = [];
            queryObj.select.forEach(item => {
                select.push(item);
            });
        }

        if (queryObj.sortBy) {
            // transform expand dots to slashes
            if (Array.isArray(queryObj.sortBy)) {
                let sortObj = [];
                queryObj.sortBy.forEach((s) => {
                    sortObj.push(s.replace('.', '/'));
                });

                queryObj.sortBy = sortObj;
            } else {
                queryObj.sortBy = queryObj.sortBy.replace('.', '/');
            }
        }

        return buildQuery( // unused options are commented, but might be used in te future
            {
                select,
                //search: queryObj.search ? queryObj.search : '',
                top: queryObj.pageSize < 0 ? null : queryObj.pageSize,
                skip: queryObj.pageIndex * queryObj.pageSize,
                //skiptoken: $skiptoken,
                //format: $format,
                filter,
                //transform,
                orderBy: queryObj.sortBy ? queryObj.sortBy : '',
                //key,
                count: queryObj.$count,
                expand: queryObj.expand,
                //action,
                //func,
                //aliases
            }
        );
    }
}