import Vue from 'vue';
import Vuetify, {
    VAlert,
    VApp,
    VNavigationDrawer,
    // VFooter,
    VList,
    VBtn,
    VIcon,
    VToolbar,
    VDataTable,
    VProgressLinear,
} from 'vuetify/lib';
import LanguageStore from '@/stores/language-store';
import en from 'vuetify/src/locale/en';
import nl from 'vuetify/src/locale/nl';

// vue-cli a-la-carte installation
Vue.use(Vuetify, {
    components: {
        VAlert,
        VApp,
        VNavigationDrawer,
        // VFooter,
        VList,
        VBtn,
        VIcon,
        VToolbar,
        VDataTable,
        VProgressLinear,
    },
});

export default new Vuetify({
    lang: {
        locales: { en, nl },
        current: LanguageStore.getLanguage().languageCode,
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#59BBD2',
                secondary: '#1383d0',
                accent: '#82B1FF',
                error: '#ea2227',
                info: '#2196F3',
                success: '#8bc541',
            },
            dark: {
                primary: '#59BBD2',
                secondary: '#1383d0',
                accent: '#82B1FF',
                error: '#ea2227',
                info: '#2196F3',
                success: '#8bc541',
            },
        },
    },
    icons: {
        iconfont: "mdi"
    }
});