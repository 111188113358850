export class Mutex {
    static mutex = Promise.resolve();

    static lock(): PromiseLike<() => void> {
        let begin: (unlock: () => void) => void = unlock => { };

        Mutex.mutex = Mutex.mutex.then(() => {
            return new Promise(begin);
        });

        return new Promise(res => {
            begin = res;
        });
    }

    static async dispatch<T>(fn: (() => T) | (() => PromiseLike<T>)): Promise<T> {
        const unlock = await Mutex.lock();
        try {
            return await Promise.resolve(fn());
        } finally {
            unlock();
        }
    }
}