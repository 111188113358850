import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { NameValue } from '../../../shared/models/dto/general';

@Component
export default class ErrorMessageComponent extends AuthComponentBase {
    @Prop({ default: [] }) readonly errors: NameValue[];

    //@Watch('errors')
    //errorsChanged() {
    //    console.log(this.errors);
    //    debugger;
    //}
}