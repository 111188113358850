import './login.scss';

import { Component, Watch } from 'vue-property-decorator';
import AuthComponentBase from '@/shared/application/auth-component-base';
import { NameValue } from '../../shared/models/dto/general';
import { Login } from '../../shared/models/dto/account';
import { User } from '../../shared/models/dto/users';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class LoginComponent extends AuthComponentBase {
    refs = this.$refs as any;
    loginInput = {} as Login;
    errors: NameValue[] = [];

    mounted() {
        this.authStore.removeUser();
        this.AppConsts.auth.removeProps();
        this.$root.$emit('authenticated', false);
    }

    @Watch('loginInput', { immediate: true })
    async onLoginInputChanged() {
        this.errors = [];
    }

    async onSubmit() {
        if (this.refs.form.validate()) {
            this.errors = [];

            await this.authService.login<User>('/api/login', this.loginInput).then((response) => {
                if ((response as any).isAxiosError) {
                    if ((response as any).response && (response as any).response.data && (response as any).response.data.errors) {
                        (response as any).response.data.errors.forEach(e => this.errors.push(e));
                    }
                } else {
                    if (!response.isError) {
                        this.$root.$emit('authenticated', true);
                        this.$router.push({ path: '/home' });
                        this.$startSignalR(response.content.requestToken.accessToken);
                    } else {
                        response.errors.forEach(e => this.errors.push(e));
                    }
                }
            });
        }
    }
}