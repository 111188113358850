import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { logger } from '../../../shared/application/app-logger';
import { TranslationObject, Translation } from '../../../shared/models/shared/translation-object';

@Component
export default class TranslationInputComponent extends AuthComponentBase {
    @Prop({ default: '' }) value: any;
    @Prop({ default: '' }) readonly modelKey: string;
    @Prop({ default: false }) readonly required: boolean;

    allTranslationObj: TranslationObject[] = [];
    currentTranslations: Translation[] = [];
    reloading: boolean = false;
    show: boolean = false;
    saving: boolean = false;

    @Watch('show', { immediate: false })
    onShowChanged() {
        if (this.show) {
            this.allTranslationObj = [];
            this.currentTranslations = [];

            if (this.value['translation']) {
                try {
                    this.allTranslationObj = JSON.parse(this.value['translation']);
                } catch (e) {
                    logger.error('Cannot parse translations: ', this.value['translation']);
                }
            } else {
                const newTranslation: TranslationObject = {
                    key: this.modelKey,
                    translations: []
                };

                // for all available languages (defined in main.ts) add an empty object
                Object.keys(this.$i18n.messages).forEach(lang => {
                    newTranslation.translations.push({
                        lang: lang,
                        translation: ''
                    });
                });

                this.allTranslationObj.push(newTranslation);
            }

            this.currentTranslations = this.allTranslationObj.find(x => x.key === this.modelKey).translations;
        } else { 
            this.save(); 
        }
    }

    updateValue($event) {
        this.$emit('update:modelValue', $event);
    }

    reload() {
        // when the parent updates the value, update problems can occur
        // give parent el a ref: <translation-input ref="translator"/>
        // and call this function like this: this.refs.translator.reload();
        this.reloading = true;
        this.$nextTick(() => {
            this.reloading = false;
        })
    }

    save() {        
        const hasTranslation = this.currentTranslations.some(t => t.translation );

        if (hasTranslation) { 
            const foundIndex = this.allTranslationObj.findIndex(x => x.key === this.modelKey);
            this.allTranslationObj[foundIndex].translations = this.currentTranslations;

            this.$emit('update:translations', JSON.stringify(this.allTranslationObj));
        }
    }

    close() {
        this.show = false;
    }
}