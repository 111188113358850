import { Filter } from 'vue-ts-decorate';
import { logger } from '@/shared/application/app-logger';

@Filter('reverseArray')
export default class ReverseArray { 
    filter(value: any[]): any[] {
        logger.log(value);
        if (!value) { 
            return []; 
        }

        return value.slice().reverse();
    }
} 