import { GetterTree } from 'vuex';
import { DateTime } from 'luxon';

import State from './state';

// GetterTree<[current state], [root state]>
const getters: GetterTree<State, State> = {

	/* General */
	startDate: (state) => {
		if (state.periodRangeType === 'day') {
			return DateTime.fromJSDate(state.selectedDate).startOf('minute').toJSDate();
		} else {
			return DateTime.fromJSDate(state.selectedDate).startOf(state.periodRangeType as any).toJSDate();
		}
	},
	endDate: (state) => {
		return DateTime.fromJSDate(state.selectedDate).endOf(state.periodRangeType as any).toJSDate();
	},
	selectedInterval: (state) => {
		const luxSelectedDate = DateTime.fromJSDate(state.selectedDate);

		if (state.periodRangeType === 'year') {
			return luxSelectedDate.toLocaleString({ year: 'numeric' });
		} else if (state.periodRangeType === 'month') {
			return luxSelectedDate.toLocaleString({ month: 'long', year: 'numeric' });
		} else if (state.periodRangeType === 'week') {
			return 'Week ' + luxSelectedDate.weekNumber + ' - ' + luxSelectedDate.toLocaleString({ year: 'numeric' });
		} else { // day
			return luxSelectedDate.toFormat('dd-MM-yyyy');
		}
	},
};

export default getters;
