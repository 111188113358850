import { DateTime } from 'luxon';
import { TokenRequest } from '../shared/models/dto/account';
import { Permission } from '../shared/models/dto/permissions';
import { User } from '../shared/models/dto/users';

export default class AuthStore {
    public static storageKey = 'user';

    public static getUser(): User {
        const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
        try {
            const returnObj = JSON.parse(localStorage.getItem(AuthStore.storageKey), (key, value) => {
                // convert date strings to date objects
                if (typeof value === "string" && ISO_8601_FULL.test(value)) {
                    return DateTime.fromISO(value, { zone: 'utc' }).toJSDate();
                }
                return value;
            }) as User;
            return returnObj;
        } catch (err) {
            return;
        }
    }

    public static setUser(user: User) {
        localStorage.setItem(AuthStore.storageKey, JSON.stringify(user));
    }

    public static removeUser(): void {
        localStorage.removeItem(AuthStore.storageKey);
    }

    public static isSignedIn(): boolean {
        return !!AuthStore.getUser();
    }

    public static isAllowedOnRoute(routePermissions: string[], grantedPermissions: Permission[]): boolean {
        let returnVal = false;

        if (grantedPermissions && grantedPermissions.length) {
            grantedPermissions.forEach((grantedPermission: Permission) => {
                if (routePermissions.indexOf(grantedPermission.name) > -1) {
                    returnVal = true;
                }
            });
        }

        return returnVal;
    }

    public static getTokenData() {
        const tokenObj = AuthStore.getUser().requestToken;
        const token = tokenObj && typeof tokenObj === 'object' ? tokenObj.accessToken : '';
        if (token) {
            return JSON.parse(atob(token.split('.')[1]));
        }

        return {};
    }

    public static refreshToken(tokenObj: TokenRequest) { 
        const user: User = AuthStore.getUser();

        user.requestToken = tokenObj;

        AuthStore.setUser(user);
    }
}